@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Crimson Pro", sans-serif;
}

@layer components {
  .btn {
    @apply px-3 py-2 rounded-md text-white bg-primary border;
  }

  .btn-squared {
    @apply px-3 rounded-md text-white bg-primary border active:opacity-80;
  }

  .btn-transparent {
    @apply px-3 py-2 rounded-md text-primary bg-transparent border border-primary;
  }

  .select {
    @apply block w-full px-4 py-2 rounded-md text-gray-700 bg-gray-100;
  }

  .textarea {
    @apply w-full px-4 py-2 rounded-md text-gray-700 border;
  }

  .toggler {
    @apply flex justify-center border rounded-md;
  }

  .toggler>* {
    @apply px-2;
  }

  .toggler .active {
    @apply text-white bg-primary;
  }
}

.p-selectbutton {
  @apply border rounded-lg;
}

.p-selectbutton .p-button {
  @apply px-5 py-2;
}

.p-selectbutton .p-button.p-highlight {
  @apply text-white bg-primary rounded-lg;
}

.p-splitbutton {
  @apply text-white bg-primary rounded-lg;
  @apply px-4 py-2 gap-2;
}

.p-menu {
  @apply border rounded-lg bg-white;
}

.p-menuitem {
  @apply py-2 px-4;
}

.p-menu .p-menu-list .p-menuitem:not(:last-child) {
  @apply border-b;
}

.p-dialog {
  @apply bg-white rounded-lg border;
}

.p-dialog .p-dialog-header {
  @apply p-4 border-b;
}

.p-dialog .p-dialog-header .p-dialog-title {
  @apply text-xl;
}

.p-dialog .p-dialog-header .p-dialog-header-icons {
  @apply self-center;
}

.p-dialog .p-dialog-content {
  @apply p-4;
}

.p-component-overlay {
  background-color: rgba(0, 0, 0, 0.5) !important;
  transition-duration: 0.2s;
}

.p-radiobutton {
  width: 22px;
  height: 22px;
}

.p-radiobutton .p-radiobutton-box {
  border: 2px solid #ced4da;
  background: #ffffff;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
  @apply text-primary;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  @apply bg-primary
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  /* box-shadow: 0 0 0 0.2rem #BFDBFE; */
  @apply border-primary;
}

.p-radiobutton .p-radiobutton-box .p-radiobutton-icon {
  width: 12px;
  height: 12px;
  transition-duration: 0.2s;
  background-color: #ffffff;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  @apply border-primary bg-primary;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  @apply border-primary bg-primary;
  color: #ffffff;
}

p-radiobutton.ng-dirty.ng-invalid>.p-radiobutton>.p-radiobutton-box {
  border-color: #e24c4c;
}

.p-input-filled .p-radiobutton .p-radiobutton-box {
  background-color: #e9ecef;
}

.p-input-filled .p-radiobutton .p-radiobutton-box:not(.p-disabled):hover {
  background-color: #e9ecef;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight {
  @apply bg-primary;
}

.p-input-filled .p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  @apply bg-primary;
}

.p-radiobutton-label {
  margin-left: 0.5rem;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  @apply border-r hover:border-r-2;
  /* background-repeat: no-repeat; */
  /* background-origin: content-box; */
  /* box-sizing: border-box; */
  /* background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+'); */
  /* background-position: bottom right; */
}

.react-resizable-handle-se {
  bottom: 0;
  right: 0;
  cursor: ew-resize;
}

.p-checkbox {
  width: 16px;
  height: 16px;
}

.p-checkbox .p-checkbox-box {
  border: 2px solid #d4d4d8;
  background: #ffffff;
  width: 16px;
  height: 16px;
  color: #3f3f46;
  border-radius: 0.375rem;
  transition: none;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  transition-duration: 0.2s;
  color: #ffffff;
  font-size: 10px;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon.p-icon {
  width: 10px;
  height: 10px;
}

.p-checkbox .p-checkbox-box.p-highlight {
  @apply bg-primary border-primary;
}

.p-checkbox .p-checkbox-box.p-highlight:not(.p-disabled):hover {
  @apply bg-primary border-primary;
  color: #ffffff;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: #d4d4d8;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  @apply border-primary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  @apply bg-primary border-primary;
  color: #ffffff;
}

.p-checkbox.p-invalid>.p-checkbox-box {
  border-color: #f0a9a7;
}

.p-input-filled .p-checkbox .p-checkbox-box {
  background-color: #fafafa;
}

.p-input-filled .p-checkbox .p-checkbox-box.p-highlight {
  @apply bg-primary;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  background-color: #fafafa;
}

.p-input-filled .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  @apply bg-primary;
}

.p-tooltip .p-tooltip-text {
  @apply bg-primary text-white p-2 rounded-md;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  @apply border-r-primary;
}
.p-tooltip.p-tooltip-left .p-tooltip-arrow {
  @apply border-l-primary;
}
.p-tooltip.p-tooltip-top .p-tooltip-arrow {
  @apply border-t-primary;
}
.p-tooltip.p-tooltip-bottom .p-tooltip-arrow {
  @apply border-b-primary;
}

.p-inputtext {
  @apply w-full px-2 rounded-md text-gray-700 border;
}
